<template>
  <FormKit
    type="checkbox"
    name="sepaConfirm"
    validation="accepted"
    validation-label="Lastschriftmandat"
  >
    <template #label>
      <div class="formkit-label">
        <p>
          <span class="fw-bolder">SEPA-Lastschriftmandat:</span>
          Ich ermächtige die Polarstern GmbH, Zahlungen von meinem Konto mittels
          Lastschrift einzuziehen. Zugleich weise ich hiermit mein Kreditinstitut an, die
          von der Polarstern GmbH auf mein Konto gezogenen Lastschriften einzulösen. Ich
          kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die
          Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem
          Kreditinstitut vereinbarten Bedingungen.
        </p>
        <p class="mb-3">
          Polarstern GmbH, Lindwurmstraße 88, 80337 München, CI: DE60ZZZ00000259140.
        </p>
      </div>
    </template>
  </FormKit>
</template>
