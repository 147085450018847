<template>
  <FormKit
    name="iban"
    label="IBAN*"
    validation="required|isIbanValid"
    :validation-rules="{ isIbanValid }"
    :validation-messages="{ isIbanValid: 'IBAN ungültig' }"
    validation-visibility="blur"
  />
</template>

<script setup lang="ts">
import type { FormKitNode } from "@formkit/core";
import isIban from "~/utils/isIban";

const isIbanValid = (node: FormKitNode) => {
  return typeof node.value === "string" ? isIban(node.value) : false;
};
</script>

<style lang="scss"></style>
